import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import { GenericSignupForm } from "../components/SignupForm"

class About extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Jakob"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>About Jakob</h1>
        <div className="blog-post-two-columns">
          <p>
            My name is Jakob and I’m a programmer living in Oslo, Norway. I have
            coded web applications for a long time, both frontend and backend.
            The last couple of years I have focused my efforts on JavaScript,
            React and the ecosystem around it. And that is what I write about on
            this site.
          </p>
          <Image
            fixed={this.props.data.avatar.childImageSharp.fixed}
            alt={"jakob"}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 100,
            }}
          />
        </div>
        <p>
          You can contact me on{" "}
          <a href="https://twitter.com/karljakoblind">Twitter</a>.
        </p>
      </Layout>
    )
  }
}

export default About
export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        title
        social {
          twitter
        }
      }
    }
  }
`
