import React, { useState } from "react"
import * as styles from "./SignupForm.module.css"

export const SignupForm = ({
  children,
  buttonText,
  buttonStyle,
  signupText,
  dripId,
}) => (
  <div className={styles.signupFormArea}>
    <form
      action={`https://www.getdrip.com/forms/${dripId}/submissions`}
      method="post"
      data-drip-embedded-form={dripId}
    >
      {children}
      <div>
        <input
          className={styles.signupField}
          placeholder="Your Email"
          type="email"
          id="drip-email"
          name="fields[email]"
        />
      </div>
      <div>
        <input
          className={styles.signupField}
          placeholder="Your Name"
          type="text"
          id="drip-first-name"
          name="fields[first_name]"
        />
      </div>
      <div className={styles.signupButtonArea}>
        <input
          className={buttonStyle || styles.myButton}
          type="submit"
          value={buttonText || "Send Me Lesson 1"}
          data-drip-attribute="sign-up-button"
        />
        <br />
        {signupText}
      </div>
    </form>
  </div>
)

function ConvertKitSignupForm({
  children,
  buttonText,
  buttonStyle,
  signupText,
  formId = "915821",
}) {
  const [errorMessage, setErrorMessage] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  const clearError = () => {
    if (email !== "" && name !== "") {
      setErrorMessage("")
    }
  }

  const onSubmit = e => {
    if (email === "" || name === "") {
      setErrorMessage("Please no empty inputs")
      e.preventDefault()
    }
  }
  return (
    <div className={styles.signupFormArea}>
      <form
        onSubmit={onSubmit}
        method="post"
        action={`https://app.convertkit.com/forms/${formId}/subscriptions`}
      >
        {children}
        <div className={styles.signupFormValidationError}>{errorMessage}</div>
        <div>
          <input
            aria-label="Your Email"
            id="email"
            className={styles.signupField}
            onChange={e => {
              clearError()
              setEmail(e.target.value)
            }}
            style={
              errorMessage && email === "" ? { border: "1px solid red" } : {}
            }
            placeholder="Your Email"
            name="email_address"
            type="email"
          />
        </div>
        <div>
          <input
            aria-label="Your Name"
            className={styles.signupField}
            onChange={e => {
              clearError()
              setName(e.target.value)
            }}
            style={
              errorMessage && name === "" ? { border: "1px solid red" } : {}
            }
            name="first_name"
            placeholder="Your Name"
          />
        </div>
        <input type="hidden" name="api_key" value="qEqWamI6-oY7MOPZSU7qfw" />
        <div className={styles.signupButtonArea}>
          <input
            className={buttonStyle || styles.myButton}
            type="submit"
            value={buttonText || "Send Me Lesson 1"}
          />
          <br />
          {signupText}
        </div>
      </form>
    </div>
  )
}

export function withHidden(WrappedComponent, text) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hidden: true }
      this.show = this.show.bind(this)
    }
    show() {
      this.setState({ hidden: false })
    }
    render() {
      return (
        <div>
          {this.state.hidden ? (
            <button className={styles.link} onClick={this.show}>
              {text}
            </button>
          ) : (
            <WrappedComponent {...this.props} />
          )}
        </div>
      )
    }
  }
}

export const SampleChapterSignupForm = ({ buttonText }) => (
  <SignupForm
    buttonText={buttonText || "Send me a sample chapter"}
    buttonStyle={styles.blueButton}
    signupText="You'll also get articles related to webpack and JavaScript once or twice per month"
    dripId={"78049842"}
  />
)
export const WebpackReduceBundleSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText || "Sign up"}>
    <h3>even more ways to reduce your webpack bundle size</h3>
    <div>
      Sign up to get a a free guide with 7 ways to optimize your bundle size.
      <br />
      <br />
      You will also get on my list to be first to know when I post new articles!
    </div>
  </ConvertKitSignupForm>
)

export const CodeYourOwnReduxSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText || "Send me the mini-ebook!"}>
    <h3>Get the free mini-ebook: Code your own Redux</h3>
    <div>
      You will also get an email when I post new articles (roughly once a week).
    </div>
  </ConvertKitSignupForm>
)

export const SendMeParcelCodeSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm
    buttonText={buttonText || "Send me the code on email"}
    signupText="You'll also get fresh articles about Redux/React a few times per month."
  >
    <h3>Get the code from this post</h3>
  </ConvertKitSignupForm>
)
export const TryParcelSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText || "Sign Up"}>
    <h3>Get started with Parcel</h3>
    <div>
      Get the source code for a minimal React project using Parcel by signing
      up!
      <br />
      <br />
      You will also get notified when I post new content.
      <br />
    </div>
  </ConvertKitSignupForm>
)

export const GenericSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText || "Sign up"}>
    <h3>Get on the list!</h3>
    <div>
      Never miss out on a post. Sign up to get notified when I publish new
      articles and get exclusive offers.
    </div>
  </ConvertKitSignupForm>
)

export const GetCodeSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText || "Sign up"}>
    <h3>Get the full code from this article</h3>
    <div>
      Sign up now to get access to the code!
      <br />
      <br />
      You will also get notified when I post new content!
      <br />
    </div>
  </ConvertKitSignupForm>
)

export const CourseSignupForm = ({ buttonText }) => (
  <ConvertKitSignupForm buttonText={buttonText} formId="916826">
    <h3>free webpack email course</h3>
    <div className={styles.columnsContainer}>
      <div className={styles.left}>
        It's time to learn webpack properly. You get 5 emails in 5 days.
        <ul>
          <li>Lesson 1: What does webpack do? (an overview)</li>
          <li>Lesson 2: Create your first webpack project</li>
          <li>Lesson 3: The webpack.config.js and Babel</li>
          <li>Lesson 4: Create a React app with webpack</li>
          <li>Lesson 5: Styling with webpack</li>
        </ul>
      </div>
      <div className={styles.right}>
        <img
          alt="webpack course"
          src="https://d4jyn86spyrm8.cloudfront.net/webpack-course.jpeg"
        />
      </div>
    </div>
  </ConvertKitSignupForm>
)
